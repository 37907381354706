<template>
  <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`">
    <v-layout
      wrap
      layout>
      <v-flex
        xs12
        md12>
        <p
          style="color: #ff6d6d;"
          class="font-weight-bold">Thông tin nhận voucher</p>
        <div>
          <v-icon
            class="mr-2"
            color="#01baef">mdi-account</v-icon>
          <span>
            {{ fullname }}
          </span>
          <span class="mx-3">|</span>
          <span>{{ phone }}</span>
        </div>
        <!-- <span class="d-block"><v-icon
          class="mr-2"
          color="#01baef">mdi-map-marker</v-icon>LA3 Chung cư La Astoria, 383 Nguyễn Duy Trinh, phường Bình Trưng Tây, Quận 12, TP HCM</span> -->
        <div class="d-flex justify-content-around">
          <div>
            <span><v-icon
              class="mr-2"
              color="#01baef">mdi-email</v-icon>{{ email }}</span>
          </div>
          <div
            class="text-right"
            @click="goToDeliveryInfo()">
            <v-icon
              medium
              @click="goToDeliveryInfo()">mdi-chevron-right</v-icon>
          </div>
        </div>
      </v-flex>
      <v-flex
        xs12
        md12
        class="mt-2 py-2"
        style="border-bottom: 1px solid #e5e5e5; border-top: 1px solid #e5e5e5;">
        <div class="d-flex justify-content-around align-center">
          <div>
            <span>Đơn hàng <span class="font-weight-bold">#{{ orderCode }}</span></span>
          </div>
        </div>
      </v-flex>
      <v-flex
        class="mt-4"
        xs12
        md7>
        <v-layout
          wrap
          layout>
          <v-flex xs12>
            <v-layout
              v-for="(cart, index) in cartList"
              :key="cart + index"
              class="pt-1 pb-1"
              wrap
              layout>
              <v-flex
                xs4
                md4>
                <figure
                  class="sixteen-nine-img">
                  <img
                    :src="cart.image">
                </figure>
              </v-flex>
              <v-flex
                class="pl-2"
                xs7
                md7>
                <p class="font-weight-bold">{{ cart.name }}</p>
                <div class="font-weight-bold mt-1">
                  <div
                    v-if="cart.pricePromo"
                    class="mr-2"
                    style="color: #ff6d6d;">{{ formatMoney(cart.pricePromo) }} VND</div>
                  <div
                    :style="cart.pricePromo ? 'text-decoration: line-through; font-size: 12px; font-weight: normal; color: #4B4B4B;' : 'color: #ff6d6d;'">{{ formatMoney(cart.price) }} VND</div>
                </div>
              </v-flex>
              <v-flex
                xs1
                md1>
                <div>
                  <p>x<span class="font-weight-bold">{{ cart.quantity }}</span></p>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        class="mt-4"
        style="border-top: 1px solid #e5e5e5;"
        xs12
        md12>
        <div class="mt-4 d-inline-block">
          <div class="d-inline-block">
            <v-icon>mdi-credit-card</v-icon>
          </div>
          <span class="ml-2 d-inline-block">{{ $t('evoucher.pay') }}</span>
        </div>
      </v-flex>
      <v-flex
        class="mt-4"
        style="border-top: 1px solid #e5e5e5;">
        <div class="d-flex mt-4">
          <p>{{ $t('cart.provisional') }}</p>
          <p class="text-right font-weight-bold">{{ getTotalPrice() }}</p>
        </div>
        <div class="d-flex">
          <p>{{ $t('thirdParty.transportFee') }}</p>
          <p class="text-right font-weight-bold">{{ 0 }}</p>
        </div>
        <div style="width: 100%; height: 1px; background-color: #e5e5e5;"/>
        <div class="d-flex mt-4">
          <p>{{ $t('thirdParty.total') }}</p>
          <p class="text-right font-weight-bold">{{ getTotalPrice() }}</p>
        </div>
      </v-flex>
      <v-flex
        class="mt-2"
        style="border-top: 1px solid #e5e5e5;"
        xs12
        md12>
        <div class="d-flex mt-4">
          <div>
            <span class="d-block font-weight-bold">{{ $t('thirdParty.total') }}</span>
            <span
              class="font-weight-bold"
              style="color: #ff6d6d;">{{ getTotalPrice() }}</span>
          </div>
          <v-btn
            :loading="isLoading"
            color="#ff6d6d"
            style="color: #fff;"
            @click="onGoChoosePaymentMethod()">
            {{ $t('thirdParty.order') }}
          </v-btn>
        </div>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import functionUtils from 'utils/functionUtils'
import VoucherProductPromotionType from 'enum/voucherProductPromotionType'
import ToastType from 'enum/toastType'
import StoreChannelType from 'enum/storeChannelType'
import stringUtils from 'utils/stringUtils'
export default {
  data: () => ({
    cartList: [],
    promotionCodeRules: [],
    promotionCode: null,
    valid: false,
    promotionCodeValid: false,
    priceReduced: 0,
    finalPrice: 0,
    promotionType: null,
    promotionId: null,
    promotionCodeOrigin: null,
    isLoading: false,
    tax: 0,
    paymentMethods: [],
    paymentMethodSelected: null,
    e1: 2,
    orderCode: null,
    viVietSessionArr: [],
    totalPrice: 0,
    userId: null,
    fullname: null,
    email: null,
    phone: null
  }),
  computed: {
    ...mapGetters([
      'GET_VOUCHER_ORDER_CART_LIST_DATA',
      'GET_SNACK_BAR'
    ])
  },
  watch: {
    GET_VOUCHER_ORDER_CART_LIST_DATA () {
      let data = this.GET_VOUCHER_ORDER_CART_LIST_DATA
      this.getVoucherOrderCart(data)
    }
  },
  created () {
    let data = this.GET_VOUCHER_ORDER_CART_LIST_DATA
    this.getVoucherOrderCart(data)
    let viVietSession = sessionStorage.getItem('viVietSession')
    this.viVietSessionArr = JSON.parse(viVietSession)
    this.userId = this.viVietSessionArr[4]
    let filter = {
      params: {
        userId: this.userId
      }
    }
    this.GET_LIEN_VIET_DELIVERY_INFO(filter).then(
      function (res) {
        let data = res.data
        this.phone = data.phone
        this.email = data.email
        this.fullname = data.fullname
      }.bind(this)
    )
  },
  methods: {
    goToDeliveryInfo: function () {
      this.$router.push({
        path: '/delivery-info'
      })
    },
    /**
     * Remove product from cart
     */
    onRemoveProductFromCart: function (productName, index) {
      this.REMOVE_PRODUCT_FROM_CART(index).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    /**
     * Get price reduced
     */
    getPriceReduced: function () {
      // eslint-disable-next-line eqeqeq
      if (this.promotionType == VoucherProductPromotionType.PRICE) {
        return this.formatMoney(this.priceReduced)
      // eslint-disable-next-line eqeqeq
      } else if (this.promotionType == VoucherProductPromotionType.PERCENT) {
        return this.priceReduced + '%'
      }
    },
    /**
    * Go choose payment method
    */
    onGoChoosePaymentMethod: function () {
      let userId = this.viVietSessionArr[4]
      let filter = {
        cartList: this.cartList,
        orderCode: this.orderCode,
        userId: this.userId
      }
      this.CONFIRM_ORDER_ON_VI_VIET(filter).then(
        function () {
          let accessCode = this.viVietSessionArr[0]
          let agentSession = this.viVietSessionArr[1]
          let merchantSite = this.viVietSessionArr[2]
          let vivietEcomUrl = this.viVietSessionArr[5]
          let returnUrl = functionUtils.removeDoubleSlashesFromUrl(`${stringUtils.DOMAIN_BASE_PATH_ADMIN}/payment-results`)
          let filter = {
            accessCode: accessCode,
            agentSession: agentSession,
            merchantSite: merchantSite,
            userId: userId,
            orderCode: this.orderCode,
            agentSite: '1',
            returnUrl: returnUrl,
            totalPrice: this.totalPrice.toString()
          }
          this.callbackVoucherOrderCart()
          this.isLoading = true
          this.PAYMENT_ORDER_ON_VI_VIET(filter).then(
            function (res) {
              let data = res.data
              this.isLoading = false
              window.open(`${vivietEcomUrl}/?merchant_site=${merchantSite}&data=${data.data}&key=${data.key}`)
            }.bind(this)
          )
        }.bind(this)
      ).catch(
        function (error) {
          if (!functionUtils.isNull(error.response.data.errorText)) {
            let errorText = error.response.data.errorText
            this.ON_SHOW_TOAST({
              'message': this.$t(`${errorText}`),
              styletype: ToastType.ERROR
            })
          } else {
            this.ON_SHOW_TOAST({
              'message': this.$t('errorBuying.errorBuying'),
              'styleType': ToastType.ERROR
            })
          }
        }.bind(this)
      )
    },
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    /**
     * Get total price
     */
    getTotalPrice: function () {
      let price = 0
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        price += (this.cartList[i].pricePromo ? this.cartList[i].pricePromo : this.cartList[i].price) * this.cartList[i].quantity
      }
      this.totalPrice = price
      return functionUtils.convertFormatNumberToMoney(price.toString())
    },
    /**
     * Get total price with fee
     */
    getTotalPriceWithFee: function () {
      let price = 0
      for (let i = 0, size = this.cartList.length; i < size; i++) {
        price += this.cartList[i].price * this.cartList[i].quantity
      }
      return functionUtils.convertFormatNumberToMoney(
        (price + (price * this.tax) / 100).toString()
      )
    },
    getVoucherOrderCart: function (data) {
      this.cartList = []
      data.forEach(e => {
        let item = {
          id: e.voucher_info.workpiece_id,
          image: e.voucher_info.image,
          quantity: e.quantity,
          price: e.voucher_info.price,
          name: e.voucher_info.name,
          serial: e.voucher_info.serial,
          voucherOrderCartId: e.voucher_order_cart_id,
          warehouse: e.warehouse,
          issuanceId: e.issuance_id
        }
        this.cartList.push(item)
      })
      let orderCodeFilter = {
        storeChannelId: StoreChannelType.LIEN_VIET_POST_BANK
      }
      if (data.length !== 0) {
        this.GET_CREATE_ORDER_CODE_PREPARE(orderCodeFilter).then(
          function (res) {
            let data = res.data
            this.orderCode = data.code
          }.bind(this)
        )
      }
    },
    callbackVoucherOrderCart: function () {
      let viVietSession = sessionStorage.getItem('viVietSession')
      viVietSession = JSON.parse(viVietSession)
      let userId = viVietSession[4]
      let filter = {
        params: {
          storeChannelId: StoreChannelType.LIEN_VIET_POST_BANK,
          userId: userId
        }
      }
      this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    getPartnershipEntityId: function (entityId) {
      let partnershipEntityId = functionUtils.getSessionKey('partnershipEntityId')
      if (partnershipEntityId) {
        return partnershipEntityId
      }
      return entityId
    },
    ...mapActions([
      'REMOVE_PRODUCT_FROM_CART',
      'REMOVE_ALL_PRODUCT_FROM_CART',
      'GET_CREATE_ORDER_CODE_PREPARE',
      'ON_SHOW_TOAST',
      'PAYMENT_ORDER_ON_VI_VIET',
      'GET_LIEN_VIET_DELIVERY_INFO',
      'CONFIRM_ORDER_ON_VI_VIET',
      'GET_VOUCHER_ORDER_CART_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
figure.sixteen-nine-img {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding-top: 56.25%;
  position: relative;
}
figure.sixteen-nine-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
</style>
